import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
// @ts-expect-error
import Svg404 from "../images/404.svg";

const NotFoundPage = () => {
  return (
    <Layout>
      <section className="jumbotron jumbotron-block full theme-jumbotron__default page-404 center">
        <div className="container">
          <div className="row py-5">
            <div className="col">
              <img
                src={Svg404}
                alt="la page n'existe pas"
                className="mb-3"
              />
              <div className="d-flex flex-column align-items-center gap-3">
                <p className="h2 text-center">Oops! Cette page n'existe plus.</p>
                <Link
                  to="/"
                  title="Retour vers la page d'accueil"
                  className="btn btn-outline-dark btn-sm"
                >
                  Take me home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )
}

export default NotFoundPage
